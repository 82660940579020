import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import Movie from "../models/Movie";
import MovieCard from "./MovieCard";
type MovieListProps = {
  movies: Movie[];
};

const MovieList = ({ movies }: MovieListProps) => {
  return (
    <Container sx={{marginY: 5}}>
      <Grid container spacing={4}>
        {movies.map((movie, index) => (
          <MovieCard movie={movie} />
        ))}
      </Grid>
    </Container>
  );
};

export default MovieList;

import React, { useEffect, useState } from "react";
import "./App.css";
import MovieList from "./components/MovieList";
import Movie from "./models/Movie";
import AppBar from "./components/AppBar";
import { ThemeProvider } from "@emotion/react";

import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#c70f02',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#0066ff',
      main: '#0044ff',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
});


const App = () => {
  const [movies, setMovies] = useState<Movie[]>([]);
  const [searchValue, setSearchValue] = useState("Home");
  const getMovieRequest = async (searchValue: string | "") => {
    const url = `https://www.omdbapi.com/?s=${searchValue}&apikey=a561a5cb`;
    const response = await fetch(url);
    const responseJson = await response.json();
    if (responseJson.Search) {
      setMovies(responseJson.Search);
    }
  };

  // useEffect(() => {
  //   getMovieRequest("new");
  // },[]);

  useEffect(() => {
    getMovieRequest(searchValue);
  }, [searchValue]);

  return (
    <ThemeProvider theme={theme}>
      <div>
        <AppBar
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        ></AppBar>
        <MovieList movies={movies} />
      </div>
     </ThemeProvider>
  );
};

export default App;

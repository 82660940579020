import {
  Grid,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";

import Movie from "../models/Movie";
type MovieProps = {
  movie: Movie;
};

const MovieCard = ({ movie }: MovieProps) => {
  return (
    <Grid item xs={6} md={3} spacing={4}>
      <Card sx={{ height: 380 }}>
        {" "}
        <CardMedia
          component="img"
          image={movie.Poster}
          alt={movie.Title}
          className="img"
        />
        <CardContent>
          <Typography gutterBottom variant="h6" component="div">
            {movie.Title}
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small">ADD TO FAVOURITE</Button>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default MovieCard;
